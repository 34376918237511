import {css, registerStyles, unsafeCSS} from '@vaadin/vaadin-themable-mixin/vaadin-themable-mixin.js';
import { badge } from '@vaadin/vaadin-lumo-styles/badge.js';

const { btoa } = require('abab');
// https://codepen.io/nikhil8krishnan/pen/rVoXJa
const loaderSvg = `<svg version="1.1" id="L1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
    <circle fill="none" stroke="#fff" stroke-width="6" stroke-miterlimit="15" stroke-dasharray="14.2472,14.2472" cx="50" cy="50" r="47" >
      <animateTransform 
         attributeName="transform" 
         attributeType="XML" 
         type="rotate"
         dur="5s" 
         from="0 50 50"
         to="360 50 50" 
         repeatCount="indefinite" />
  </circle>
  <circle fill="none" stroke="#fff" stroke-width="1" stroke-miterlimit="10" stroke-dasharray="10,10" cx="50" cy="50" r="39">
      <animateTransform 
         attributeName="transform" 
         attributeType="XML" 
         type="rotate"
         dur="5s" 
         from="0 50 50"
         to="-360 50 50" 
         repeatCount="indefinite" />
  </circle>
  <g fill="#fff">
  <rect x="30" y="35" width="5" height="30">
    <animateTransform 
       attributeName="transform" 
       dur="1s" 
       type="translate" 
       values="0 5 ; 0 -5; 0 5" 
       repeatCount="indefinite" 
       begin="0.1"/>
  </rect>
  <rect x="40" y="35" width="5" height="30" >
    <animateTransform 
       attributeName="transform" 
       dur="1s" 
       type="translate" 
       values="0 5 ; 0 -5; 0 5" 
       repeatCount="indefinite" 
       begin="0.2"/>
  </rect>
  <rect x="50" y="35" width="5" height="30" >
    <animateTransform 
       attributeName="transform" 
       dur="1s" 
       type="translate" 
       values="0 5 ; 0 -5; 0 5" 
       repeatCount="indefinite" 
       begin="0.3"/>
  </rect>
  <rect x="60" y="35" width="5" height="30" >
    <animateTransform 
       attributeName="transform" 
       dur="1s" 
       type="translate" 
       values="0 5 ; 0 -5; 0 5"  
       repeatCount="indefinite" 
       begin="0.4"/>
  </rect>
  <rect x="70" y="35" width="5" height="30" >
    <animateTransform 
       attributeName="transform" 
       dur="1s" 
       type="translate" 
       values="0 5 ; 0 -5; 0 5" 
       repeatCount="indefinite" 
       begin="0.5"/>
  </rect>
  </g>
</svg>`.replaceAll("#fff", "#00f");
const loaderSvgB64 = btoa(loaderSvg);

// @see https://demo.vaadin.com/lumo-editor/
const theme = unsafeCSS(`
html {
  --loader-svg: url("data:image/svg+xml;base64,${loaderSvgB64}");
}

.loader {
    position: relative;
}

.loader:before {
    display: block;
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.65);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    z-index: 100;
}
.loader.loader-fixed:before {
    position: fixed;
}
.loader.has-error:before,
.loader.loading:before {
    visibility: visible;
    opacity: 1;
}

.loader > .loader__error-msg {
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    visibility: hidden;
    opacity: 0;
    z-index: 101;
    
    padding: var(--lumo-space-l);
    background-color: var(--lumo-base-color);
    border-radius: var(--lumo-border-radius-l);
    box-shadow: 0 0 0 1px var(--lumo-shade-5pct), var(--lumo-box-shadow-xl);
    background-image: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}
.loader.has-error > .loader__error-msg {
    visibility: visible;
    opacity: 1;
}

.loader:after {
    display: block;
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
    height: 3rem;
    background-image: var(--loader-svg);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    z-index: 101;
}
.loader.loading:after {
    visibility: visible;
    opacity: 1;
}
`);

const customTheme = css`
html {
  --lumo-border-radius: 0.125em;

  --lumo-primary-text-color: rgb(106, 115, 125);
  --lumo-primary-color-50pct: rgba(106, 115, 125, 0.5);
  --lumo-primary-color-10pct: rgba(106, 115, 125, 0.1);
  --lumo-primary-color: #6a737d;

  --lumo-error-text-color: rgb(215, 58, 73);
  --lumo-error-color-50pct: rgba(215, 58, 73, 0.5);
  --lumo-error-color-10pct: rgba(215, 58, 73, 0.1);
  --lumo-error-color: #d73a49;

  --lumo-success-text-color: rgb(40, 167, 69);
  --lumo-success-color-50pct: rgba(40, 167, 69, 0.5);
  --lumo-success-color-10pct: rgba(40, 167, 69, 0.1);
  --lumo-success-color: #28a745;
}

[theme~="dark"] {
  --lumo-base-color: #1f2428;

  --lumo-primary-text-color: rgb(209, 213, 218);
  --lumo-primary-color-50pct: rgba(209, 213, 218, 0.5);
  --lumo-primary-color-10pct: rgba(209, 213, 218, 0.1);
  --lumo-primary-color: #d1d5da;

  --lumo-error-text-color: rgb(215, 58, 73);
  --lumo-error-color-50pct: rgba(215, 58, 73, 0.5);
  --lumo-error-color-10pct: rgba(215, 58, 73, 0.1);
  --lumo-error-color: #d73a49;

  --lumo-success-text-color: rgb(40, 167, 69);
  --lumo-success-color-50pct: rgba(40, 167, 69, 0.5);
  --lumo-success-color-10pct: rgba(40, 167, 69, 0.1);
  --lumo-success-color: #28a745;
}
`;

// fix for the context menu crop bellow the navbar
registerStyles('vaadin-app-layout', css`
[part='navbar'] {
    z-index: 0 !important;
}
#navbarTop {
    height: 56px;
}
[content] {
    overflow: visible !important;
}
`, {moduleId: 'override'})

registerStyles('vaadin-split-layout', css`
:host {
    overflow: visible !important;
}
`, {moduleId: 'override'})


// background-image

registerStyles('vaadin-grid', css`
:host([loading-x]) {
    background-color: transparent;
}

[part~='cell-x'] {
    background-color: transparent !important;
}

:host:after {
    display: block;
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
    height: 3rem;
    background-image: var(--loader-svg);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

:host([loading]):after {
    visibility: visible;
    opacity: 1;
}
`, {moduleId: 'override'})

// registerStyles('vaadin-overlay', css`
// [part='backdrop'] {
//     background: var(--lumo-base-color) linear-gradient(var(--lumo-shade-5pct), var(--lumo-shade-5pct));
// }
// [part='overlay'] {
//     background: none;
//     border-radius: 0;
//     box-shadow: none;
//     width: 100%;
//     height: 100%;
// }
// [part='content'] {
//     height: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }
// `, {moduleId: 'override'})

/*
// tweak for the icon size, see vaadin-icon in index.scss
registerStyles('vaadin-icon', css`
:host {
    box-sizing: border-box;
    margin-inline-end: var(--lumo-space-m);
    margin-inline-start: var(--lumo-space-xs);
    padding: var(--lumo-space-xs);
}
`, {moduleId: 'override'})
*/

// include base badge CSS and tweak badges to have inline start margin if they are not the first childs
const customBadge = css`
${badge}

span[theme~='badge']:not(:first-child) {
    margin-inline-start: var(--lumo-space-xs);
}
`

// fix for the Edit -> E... on menu resize
registerStyles('vaadin-menu-bar', css`
[part='menu-bar-button'] {
    width: auto !important;
}
`, {moduleId: 'override'})

// import '@vaadin/vaadin-lumo-styles/all-imports.js';
import {color} from '@vaadin/vaadin-lumo-styles/color.js';
import {typography} from '@vaadin/vaadin-lumo-styles/typography.js';
import {utility} from '@vaadin/vaadin-lumo-styles/utility.js';
import '@vaadin/icons';
import '@vaadin/vaadin-lumo-styles/icons';

[color, typography, utility, customBadge, theme].forEach(function (css, index) {
    const $tpl = document.createElement('template');
    $tpl.innerHTML = `<style>${css.toString().replace(':host', 'html')}</style>`;
    // var div = document.createElement('div');
    // div.appendChild( $tpl.content.cloneNode(true) );
    // window.console.log(div.innerHTML);
    document.head.appendChild($tpl.content);
})

// // Import the <custom-style> element from Polymer and include
// // the style sheets in the global scope
// import '@polymer/polymer/lib/elements/custom-style.js';

// NOTE: For some reason the order of includes is not respected, if put into index.css Vaadin theme will be overriden by defaults!
import './index.scss';

// Prepare global environment for Scala.js implementation
import './client.ts';
