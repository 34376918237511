define(["require", "exports", "monaco-editor/esm/vs/editor/editor.main", "monaco-languageclient", "./scala.ts", "./esm/vs/basic-languages/markdown/markdown.js"], function (require, exports, monaco, monaco_languageclient_1, scala, markdown) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    // install Monaco language client services
    (0, monaco_languageclient_1.initServices)();
    self.MonacoEnvironment = {
        getWorkerUrl: () => '/assets/editor.worker-bundle.js'
    };
    self.monaco = monaco;
    // (self as any).scala = scala;
    // register Monaco languages
    monaco.languages.register({
        id: 'scala',
        extensions: ['.scala', '.sc', '.sbt'],
        aliases: ['Scala', 'scala', 'SBT', 'Sbt', 'sbt', 'Dotty', 'dotty'],
        mimetypes: ['text/plain', 'text/x-scala-source', 'text/x-scala', 'text/x-sbt', 'text/x-dotty'],
    });
    monaco.languages.setMonarchTokensProvider('scala', scala.language);
    monaco.languages.setLanguageConfiguration('scala', scala.conf);
    monaco.languages.register({
        id: 'markdown',
        extensions: ['.md', '.markdown', '.mdown', '.mkdn', '.mkd', '.mdwn', '.mdtxt', '.mdtext'],
        aliases: ['Markdown', 'markdown'],
    });
    monaco.languages.setMonarchTokensProvider('markdown', markdown.language);
    monaco.languages.setLanguageConfiguration('markdown', markdown.conf);
});
